import React from 'react'
import Layout from '../components/layout'
import styles from './image.module.scss'
import Img from 'gatsby-image'
import { graphql, Link } from 'gatsby'

export default class Image extends React.PureComponent {
  render() {
    const image = this.props.data.image.edges[0].node
    return (
      <Layout
        title={`${image.gallery.project.title} - ${image.gallery.title}${
          image.gallery.formattedDate ? `, ${image.gallery.formattedDate}` : ''
        } - ${image.title ? image.title : 'Image'}`}
        description={`A photo from ${image.gallery.title}.`}
        image={image}
        currentProject={image.gallery.project}
      >
        <main className={styles.image} style={{ maxWidth: image.width }}>
          {image.title ? <h1>{image.title}</h1> : null}
          <Img
            placeholderStyle={{
              opacity: 1,
            }}
            fadeIn={false}
            fluid={image.fluid}
            style={{
              backgroundColor: image.colors.light,
            }}
          />
          <nav>
            <Link to={image.gallery.slug}>{image.gallery.title}</Link>
          </nav>
        </main>
      </Layout>
    )
  }
}

export const query = graphql`
  query Image($slug: String!) {
    image: allGalleryImage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          src
          width
          height
          colors {
            light
          }
          gallery {
            slug
            title
            formattedDate
            project {
              name
              title
              slug
            }
          }
          fluid(maxWidth: 1600, crop: "limit") {
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  }
`
